import { Product, ProductDTO } from './Product';
import { Variant, VariantDTO } from './Product/Variant';
import Dinero, { Currency } from 'dinero.js';
import { DistributionType } from './Order';
import { toDinero } from '../formatFun';

export class OrderItem {
    id: number;
    cartId: number;
    orderId: number;
    productId: number;
    product: Product = new Product();
    distributionType: DistributionType;
    variantId: number;
    variant: Variant;
    price: Dinero.Dinero;
    tax: Dinero.Dinero;

    toApi(): OrderItemDTO {
        console.log("OrderItem toAPi() call");
        console.log(this);
        return {
            id: this.id,
            cartId: this.cartId,
            orderId: this.orderId,
            productId: this.productId,
            product: this.product.toApi? this.product.toApi(): null,
            distributionType: this.distributionType.toString(),
            variantId: this.variantId,
            variant: this.variant.toApi(),
            price: this.price.getAmount(),
            tax: this.tax.getAmount() ?? 0,
            currency: this.price.getCurrency().toString(),
        };
    }


    static createFromApi(dto: OrderItemDTO, orderCurrency: string): OrderItem {
        const currency = dto.currency || orderCurrency;
        return Object.assign(new OrderItem(), dto, {
            variant: dto.variant ? Variant.createFromApi(dto.variant) : null,
            price: toDinero(dto.price, currency),
            tax: toDinero(dto.tax ?? 0, currency),
            distributionType: dto.distributionType == "Print" ? DistributionType.Print : DistributionType.Digital,
            product: Product.createFromApi(dto.product)
        });
    }
}

export class CountedOrderItem {
    amount: number;
    orderItem: OrderItem;
}


export interface OrderItemDTO {
    id: number;
    cartId: number;
    orderId: number;
    productId: number;
    product: ProductDTO;
    distributionType: string;
    variantId: number;
    variant?: VariantDTO;
    price: number;
    tax?: number;
    currency: string;
}
