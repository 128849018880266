import { SupportTicketMessage, SupportTicketMessageDTO } from './SupportTicketMessage';
import { atom } from 'jotai';
import dashboard from '../../../API/DashboardAPI';
import dayjs, { Dayjs } from 'dayjs';
import DashUser from '../DashUser';

export class SupportTicket {
    id: number;
    from: SupportTicketFrom;
    type:ApiSubmittedSupportTicketType;
    tags: SupportTicketTag[];
    firstCreated: Dayjs;
    latestUpdate: Dayjs;
    latestInboundUpdate: Dayjs;
    assignedToUserId: number;
    status : SupportTicketStatus;
    lastMessageDirection: 'Inbound' | 'Outbound';
    platform:string;

    static createFromApi(dto: SupportTicketDTO): SupportTicket {
        return {
            id: dto.id,
            from: dto.from,
            type: dto.type,
            tags: dto.tags,
            latestUpdate: dto.latestUpdate ? dayjs.utc(dto.latestUpdate) : null,
            assignedToUserId: dto.assignedToUserId,
            status: dto.status,
            firstCreated: dto.firstCreated ? dayjs.utc(dto.firstCreated) : null,
            lastMessageDirection: dto.lastMessageDirection,
            latestInboundUpdate: dto.latestInboundUpdate ? dayjs.utc(dto.latestInboundUpdate) : null,
            platform:dto.platform
        };
    }

}

export type SupportTicketDTO = {
    id: number;
    from: SupportTicketFrom;
    type:ApiSubmittedSupportTicketType;
    tags: SupportTicketTag[];
    firstCreated: string;
    latestUpdate: string;
    assignedToUserId: number;
    status : SupportTicketStatus;
    lastMessageDirection: 'Inbound' | 'Outbound';
    latestInboundUpdate: string;
    platform:string;
}

export type SupportTicketFrom = {
    type: 'Email'
    fromEmail: string;
    fromUserId: string;
    fromUserIdEmailLookup: string;
} | {
    type: 'SubmittedForm',
    submittedUserId: string;
    submittedUserIdEmailLookup: string;
    submittedEmail: string;
}

export type ApiSubmittedSupportTicketType =
    'Email' |
    'Contact' |
    'Feedback'

export type SupportTicketTag = {
    id: number;
    tagName: string;
    tagDescription: string;
    tagColor: string;
    tagTextColor: string;
    tagOrder: number;
    quickTag: boolean;
}

export type SupportTicketStatus =
    'Unresolved' |
    'InProgress' |
    'ReOpened' |
    'Resolved';

export class SupportTicketWithMessages extends SupportTicket {
    messages: SupportTicketMessage[];

    static createFromApi(dto: SupportTicketWithMessagesDTO): SupportTicketWithMessages {
        return {
            ...SupportTicket.createFromApi(dto),
            messages: dto.messages.map(SupportTicketMessage.createFromApi)
        };
    }
}

export type SupportTicketWithMessagesDTO = SupportTicketDTO & {
    messages: SupportTicketMessageDTO[];
}

export type SupportTicketBulkEditRequest = {
    ticketIds: number[];
    assignedToUserId: number | null;
    status: SupportTicketStatus | null;
    addTagIds: number[];
    removeTagIds: number[];
}

const InnerSupportTicketTags = atom<SupportTicketTag[]>([]);

export const SupportTicketTagsAtom = atom(null, async (get, set, force: boolean) => {
    if (force || get(InnerSupportTicketTags).length === 0) {
        const result = await dashboard.getSupportTicketTags();
        set(InnerSupportTicketTags, result);
    }
    return get(InnerSupportTicketTags);
});

const InnerSupportTicketAssignableUsers = atom<DashUser[]>([]);

export const SupportTicketAssignableUsersAtom = atom(null, async (get, set, force: boolean) => {
    if (force || get(InnerSupportTicketAssignableUsers).length === 0) {
        const result = await dashboard.getSupportTicketAssignableUsers();
        set(InnerSupportTicketAssignableUsers, result);
    }
    return get(InnerSupportTicketAssignableUsers);
});
