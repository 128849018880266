import { DashThumbnail, DashThumbnailDTO } from './DashThumbnail';
import { ageRatingToInt, ageToAgeRating, AgeRating } from './AgeRating';
import { DashProduct } from './DashProduct';
import { DashSeries } from './DashSeries';

export enum DashCarouselType {
    Thumbnail = 'Thumbnail',
    Banner = 'Banner',
}

export enum DashCarouselItemType {
    Image = 'Image',
    Video = 'Video',
}

export enum DashVideoCarouselItemActionType {
    Reader = 'Reader',
    Product = 'Product',
    Series = 'Series',
}

export class DashCarousel {
    private static _reactKey = 0;
    readonly reactKey: string;

    isMarkedForRemoval = false;
    original?: DashCarousel;
    id?: number;
    type?: DashCarouselType;
    isForSteam?: boolean;
    isForWebsite?: boolean;
    isForNintendoSwitch?: boolean;
    name?: string;
    items: DashCarouselItem[];

    constructor() {
        this.reactKey = `${DashCarousel._reactKey++}`;
    }

    static create(options: {
        id?: number;
        type?: DashCarouselType;
        name?: string;
        items?: DashCarouselItem[];
    }): DashCarousel {
        const original = new DashCarousel().merge({ ...options, items: options.items ?? [] });
        return Object.assign(new DashCarousel(), original, { original });
    }

    static fromApi(dto: DashCarouselDTO): DashCarousel {
        const original = DashCarousel._originalFromApi(dto);
        return Object.assign(new DashCarousel(), original, { original });
    }

    private static _originalFromApi(dto: DashCarouselDTO): DashCarousel {
        const items = dto.items?.map(DashCarouselItem.fromApi) ?? [];
        return Object.assign(new DashCarousel(), dto, { items });
    }

    toApi(): DashCarouselDTO {
        const { id, type, isForSteam, isForWebsite, isForNintendoSwitch, name } = this;
        const items = this.items.map(item => item.toApi());
        return { id, type, isForSteam, isForWebsite, isForNintendoSwitch, name, items };
    }

    isUntyped(): boolean {
        return !this.type;
    }

    isBanner(): boolean {
        return this.type === DashCarouselType.Banner;
    }

    isThumbnail(): boolean {
        return this.type === DashCarouselType.Thumbnail;
    }

    hasChanged(): boolean {
        return !this.id ||
            this.isMarkedForRemoval ||
            !this.original ||
            !this.equals(this.original);
    }

    canRevert(): boolean {
        return !!this.original && !this.equals(this.original);
    }

    canSave(): boolean {
        if (!this.type) {
            return false;
        }
        if (!this.isForSteam && !this.isForWebsite && !this.isForNintendoSwitch) {
            return true;
        }
        return this.canPublish();
    }

    canPublish(): boolean {
        return !this.isMarkedForRemoval &&
            !!this.type &&
            !!this.name &&
            this.items.length > 0 &&
            this.items.every(item => item.canPublish(this.type)) &&
            Object.values(this.getAgeRatingStats()).every(v => v >= 4);
    }

    equals(other: DashCarousel): boolean {
        if (this.id !== other.id ||
            this.type !== other.type ||
            this.isForSteam !== other.isForSteam ||
            this.isForWebsite !== other.isForWebsite ||
            this.isForNintendoSwitch !== other.isForNintendoSwitch ||
            this.name !== other.name ||
            this.items.length !== other.items.length) {
            return false;
        }
        for (let i = 0; i < this.items.length; i++) {
            if (!this.items[i].equals(other.items[i])) {
                return false;
            }
        }
        return true;
    }

    merge(options: {
        isMarkedForRemoval?: boolean;
        id?: number;
        type?: DashCarouselType;
        isForSteam?: boolean;
        isForWebsite?: boolean;
        isForNintendoSwitch?: boolean
        name?: string;
        items?: DashCarouselItem[];
    }): DashCarousel {
        if (!options.items) {
            return Object.assign(new DashCarousel(), this, options);
        }
        const items = options.items.map((item, i) => item.ordinal === i ? item : item.merge({ ordinal: i }));
        return Object.assign(new DashCarousel(), this, { ...options, items});
    }

    duplicate(carousels: DashCarousel[]): DashCarousel {
        const name = DashCarousel._findUniqueDuplicateName(carousels, this.name);
        const items = this.items.map(i => i.duplicate());
        const original = Object.assign(new DashCarousel(), { type: this.type, name, items });
        return Object.assign(new DashCarousel(), original, { original });
    }

    private static _findUniqueDuplicateName(carousels: DashCarousel[], name: string): string {
        let originalName = name;
        if (/\s\(\d+\)$/.test(originalName)) {
            originalName = originalName.replace(/\s\(\d+\)$/, '').trim();
        }
        let i = 1;
        let newName = originalName + ` (${i})`;
        while (carousels.some(c => c.name === newName)) {
            i++;
            newName = originalName + ` (${i})`;
        }
        return newName;
    }

    revert(): DashCarousel {
        if (this.original) {
            return Object.assign(new DashCarousel(), this.original, { original: this.original });
        }
        return new DashCarousel();
    }

    getAgeRatingStats(): Record<AgeRating, number> {
        const ageRatings = [
            AgeRating.E,
            AgeRating.E7,
            AgeRating.PG,
            AgeRating.T,
            AgeRating.M,
        ];
        const stats = {
            [AgeRating.E]: 0,
            [AgeRating.E7]: 0,
            [AgeRating.PG]: 0,
            [AgeRating.T]: 0,
            [AgeRating.M]: 0,
        };
        for (const item of this.items) {
            if (!item.enabled) {
                continue;
            }
            if (item.metaData?.isGeoRestricted) {
                continue;
            }
            const minAgeRating = item.getMinAgeRating();
            const maxAgeRating = item.displayUpToAgeRating ?? AgeRating.M;
            const minIndex = ageRatings.indexOf(minAgeRating);
            const maxIndex = ageRatings.indexOf(maxAgeRating);
            for (let i = minIndex; i <= maxIndex; i++) {
                stats[ageRatings[i]]++;
            }
        }
        return stats;
    }
}

export class DashCarouselItem {
    private static _reactKey = 0;
    readonly reactKey: string;

    original: DashCarouselItem;
    type?: DashCarouselItemType;
    id?: number;
    enabled?: boolean;
    ordinal?: number;
    displayUpToAgeRating?: AgeRating;
    metaData?: DashCarouselItemMetaData;

    subtitle?: string;
    thumbnail?: DashThumbnail;
    thumbnailFallback?: DashThumbnail;

    videoId?: string;
    autoPlay?: boolean;
    muted?: boolean;
    actionType?: DashVideoCarouselItemActionType;
    buttonText?: string;
    tagline?: string;

    productId?: number | null;
    productName?: string;
    seriesId?: number | null;
    seriesName?: string;

    constructor() {
        this.reactKey = `${DashCarouselItem._reactKey++}`;
    }

    static create(options: {
        type?: DashCarouselItemType;
        id?: number;
        enabled?: boolean;
        ordinal?: number;
        displayUpToAgeRating?: AgeRating;
        metaData?: DashCarouselItemMetaData;

        subtitle?: string;
        thumbnail?: DashThumbnail;
        thumbnailFallback?: DashThumbnail;

        videoId?: string;
        autoPlay?: boolean;
        muted?: boolean;
        actionType?: DashVideoCarouselItemActionType;
        buttonText?: string;
        tagline?: string;

        productId?: number | null;
        productName?: string;
        seriesId?: number | null;
        seriesName?: string;
    }): DashCarouselItem {
        const original = new DashCarouselItem().merge(options);
        return Object.assign(new DashCarouselItem(), original, { original });
    }

    static fromApi(dto: DashCarouselItemDTO): DashCarouselItem {
        const original = DashCarouselItem._originalFromApi(dto);
        return Object.assign(new DashCarouselItem(), original, { original });
    }

    private static _originalFromApi(dto: DashCarouselItemDTO): DashCarouselItem {
        return Object.assign(new DashCarouselItem(), dto, {
            thumbnail: dto.thumbnail ? DashThumbnail.fromApi(dto.thumbnail) : undefined,
            thumbnailFallback: dto.thumbnailFallback ? DashThumbnail.fromApi(dto.thumbnailFallback) : undefined,
            metaData: dto.metaData ? DashCarouselItemMetaData.fromApi(dto.metaData) : undefined,
        });
    }

    toApi(): DashCarouselItemDTO {
        return {
            type: this.type,
            id: this.id,
            enabled: this.enabled,
            ordinal: this.ordinal,
            displayUpToAgeRating: this.displayUpToAgeRating,

            subtitle: this.subtitle,
            thumbnail: this.thumbnail ? this.thumbnail.toApi() : undefined,

            videoId: this.videoId,
            autoPlay: this.autoPlay,
            muted: this.muted,
            actionType: this.actionType,
            buttonText: this.buttonText,
            tagline: this.tagline,

            productId: this.productId,
            productName: this.productName,
            seriesId: this.seriesId,
            seriesName: this.seriesName,
        };
    }

    hasChanged(): boolean {
        return !this.id || !this.original || !this.equals(this.original);
    }

    canRevert(): boolean {
        return !!this.original && !this.equals(this.original);
    }

    canPublish(type: DashCarouselType): boolean {
        if (!this.enabled || this.ordinal === undefined || !this.displayUpToAgeRating) {
            return true;
        }
        if (type === DashCarouselType.Thumbnail) {
            if (this.type === DashCarouselItemType.Image) {
                return !!this.subtitle && (!!this.productId || !!this.seriesId);
            }
            if (this.type === DashCarouselItemType.Video) {
                if (!this.videoId || !this.actionType || !this.buttonText || !this.tagline) {
                    return false;
                }
                if (this.actionType === DashVideoCarouselItemActionType.Reader) {
                    return !!this.productId;
                }
                if (this.actionType === DashVideoCarouselItemActionType.Product) {
                    return !!this.productId;
                }
                if (this.actionType === DashVideoCarouselItemActionType.Series) {
                    return !!this.seriesId;
                }
                return false;
            }
            return false;
        }
        if (type === DashCarouselType.Banner) {
            return !!this.seriesId && !!this.thumbnail;
        }
        return true;
    }

    equals(other: DashCarouselItem): boolean {
        if (!this.thumbnail && !!other.thumbnail) {
            return false;
        }
        if (!!this.thumbnail && !other.thumbnail) {
            return false;
        }
        if (!!this.thumbnail && !!other.thumbnail && !this.thumbnail.equals(other.thumbnail)) {
            return false;
        }
        return this.type === other.type &&
            this.id === other.id &&
            !!this.enabled === !!other.enabled &&
            this.displayUpToAgeRating === other.displayUpToAgeRating &&
            this.subtitle === other.subtitle &&

            this.videoId === other.videoId &&
            !!this.autoPlay === !!other.autoPlay &&
            !!this.muted === !!other.muted &&
            this.actionType === other.actionType &&
            this.buttonText === other.buttonText &&
            this.tagline === other.tagline &&

            this.productId === other.productId &&
            this.productName === other.productName &&
            this.seriesId === other.seriesId &&
            this.seriesName === other.seriesName;
    }

    merge(options: {
        type?: DashCarouselItemType;
        id?: number;
        enabled?: boolean;
        ordinal?: number;
        displayUpToAgeRating?: AgeRating;
        metaData?: DashCarouselItemMetaData;

        subtitle?: string;
        thumbnail?: DashThumbnail;
        thumbnailFallback?: DashThumbnail;

        videoId?: string;
        autoPlay?: boolean;
        muted?: boolean;
        actionType?: DashVideoCarouselItemActionType;
        buttonText?: string;
        tagline?: string;

        productId?: number | null;
        productName?: string;
        seriesId?: number | null;
        seriesName?: string;
    }): DashCarouselItem {
        return Object.assign(new DashCarouselItem(), this, options);
    }

    duplicate(): DashCarouselItem {
        const {
            type, enabled, displayUpToAgeRating, metaData,
            subtitle,
            videoId, autoPlay, muted, actionType, buttonText, tagline,
            seriesId, seriesName, productId, productName,
        } = this;
        const thumbnail = this.thumbnail?.duplicate();
        const thumbnailFallback = this.thumbnailFallback?.duplicate();
        const original = Object.assign(new DashCarouselItem(), {
            type, enabled, displayUpToAgeRating, metaData,
            subtitle, thumbnail, thumbnailFallback,
            videoId, autoPlay, muted, actionType, buttonText, tagline,
            productId, productName, seriesId, seriesName,
        });
        return Object.assign(new DashCarouselItem(), original, { original });
    }

    revert(): DashCarouselItem {
        if (this.original) {
            return Object.assign(new DashCarouselItem(), this.original, { original: this.original });
        }
        return new DashCarouselItem();
    }

    fixDisplayUpToAgeRating(): DashCarouselItem {
        if (!this.displayUpToAgeRating) {
            return this;
        }
        const minAgeRating = this.getMinAgeRating();
        if (ageRatingToInt(minAgeRating) <= ageRatingToInt(this.displayUpToAgeRating)) {
            return this;
        }
        return this.merge({ displayUpToAgeRating: minAgeRating });
    }

    getMinAgeRating(): AgeRating {
        return this.metaData?.ageRating ?? AgeRating.E;
    }
}

export class DashCarouselItemMetaData {
    ageRating?: AgeRating;
    isGeoRestricted?: boolean;

    static fromApi(dto: DashCarouselItemMetaDataDTO): DashCarouselItemMetaData {
        return Object.assign(new DashCarouselItemMetaData(), dto);
    }

    static fromSeries(series: DashSeries): DashCarouselItemMetaData {
        return Object.assign(new DashCarouselItemMetaData(), {
            ageRating: series.getAgeRating(),
            isGeoRestricted: series.isGeoRestricted(),
        });
    }

    static fromProduct(product: DashProduct): DashCarouselItemMetaData {
        const minAge = product.ageRestrictable?.ageRating;
        const ageRating = minAge ? ageToAgeRating(minAge) : AgeRating.E;
        const isGeoRestricted = product.geoRestrictable?.restrictedTerritories?.length > 0;
        return Object.assign(new DashCarouselItemMetaData(), { ageRating, isGeoRestricted });
    }

    toApi(): DashCarouselItemMetaDataDTO {
        return { ageRating: this.ageRating, isGeoRestricted: this.isGeoRestricted };
    }
}

export interface DashCarouselDTO {
    id?: number;
    type?: DashCarouselType;
    isForSteam?: boolean;
    isForWebsite?: boolean;
    isForNintendoSwitch?: boolean;
    name?: string;
    items?: DashCarouselItemDTO[];
}

export interface DashCarouselItemDTO {
    type?: DashCarouselItemType;
    id?: number;
    enabled?: boolean;
    ordinal?: number;
    displayUpToAgeRating?: AgeRating;
    metaData?: DashCarouselItemMetaDataDTO;

    subtitle?: string;
    thumbnail?: DashThumbnailDTO;
    thumbnailFallback?: DashThumbnailDTO;

    videoId?: string;
    autoPlay?: boolean;
    muted?: boolean;
    actionType?: DashVideoCarouselItemActionType;
    buttonText?: string;
    tagline?: string;

    productId?: number;
    productName?: string;
    seriesId?: number;
    seriesName?: string;
}

export interface DashCarouselItemMetaDataDTO {
    ageRating?: AgeRating;
    isGeoRestricted?: boolean;
}
