import dayjs, { Dayjs } from 'dayjs';

export class SupportTicketMessage {
    id: number;
    message: string;
    submittedDateTime: Dayjs;
    type: ApiSubmittedSupportTicketBaseType;
    direction: "Inbound" | "Outbound";

    static createFromApi(dto: SupportTicketMessageDTO): SupportTicketMessage {
        switch (dto.type) {
            case 'Email':
                return EmailSupportTicketMessage.createFromApi(dto as EmailSupportTicketMessageDTO);
            case 'SubmittedForm':
                return SubmittedFormSupportTicketMessage.createFromApi(dto as SubmittedFormSupportTicketMessageDTO);
            default:
                throw new Error(`Unknown SupportTicketMessage type: ${dto.type}`);
        }
    }
}
export type ApiSubmittedSupportTicketBaseType =
    "Email"|"SubmittedForm";
export type SupportTicketMessageDTO = {
    id: number;
    message: string;
    submittedDateTime: string;
    type: ApiSubmittedSupportTicketBaseType;
    direction: "Inbound" | "Outbound";
}

export class EmailSupportTicketMessage extends SupportTicketMessage {
    fromEmail: string;
    toEmail: string;
    subject: string;
    ccEmail: string;

    static createFromApi(dto: EmailSupportTicketMessageDTO): EmailSupportTicketMessage {
        switch (dto.direction) {
            case 'Inbound':
                return InboundEmailSupportTicketMessage.createFromApi(dto as InboundEmailSupportTicketMessageDTO);
            case 'Outbound':
                return OutboundEmailSupportTicketMessage.createFromApi(dto as OutboundEmailSupportTicketMessageDTO);
            default:
                throw new Error(`Unknown EmailSupportTicketMessage direction: ${dto.direction}`);
        }
    }
}

type EmailSupportTicketMessageDTO = SupportTicketMessageDTO & {
    fromEmail: string;
    toEmail: string;
    subject: string;
    ccEmail: string;
}

export class InboundEmailSupportTicketMessage extends EmailSupportTicketMessage {
    bucketLocationKey: string;
    emailSizeInBytes: number;

    static createFromApi(dto: InboundEmailSupportTicketMessageDTO): InboundEmailSupportTicketMessage {
        const supportTicketMessage = new InboundEmailSupportTicketMessage();
        supportTicketMessage.id = dto.id;
        supportTicketMessage.message = dto.message;
        supportTicketMessage.submittedDateTime = dayjs.utc(dto.submittedDateTime);
        supportTicketMessage.type = dto.type;
        supportTicketMessage.bucketLocationKey = dto.bucketLocationKey;
        supportTicketMessage.fromEmail = dto.fromEmail;
        supportTicketMessage.subject = dto.subject;
        supportTicketMessage.ccEmail = dto.ccEmail;
        supportTicketMessage.emailSizeInBytes = dto.emailSizeInBytes;
        supportTicketMessage.direction = dto.direction;
        supportTicketMessage.toEmail = dto.toEmail;
        return supportTicketMessage;
    }
}

type InboundEmailSupportTicketMessageDTO = EmailSupportTicketMessageDTO & {
    bucketLocationKey: string;
    emailSizeInBytes: number;
}

export class OutboundEmailSupportTicketMessage extends EmailSupportTicketMessage {
    externalEmailId: string;
    status:"Scheduled"| "Sending" | "Sent" | "Failed" | "Cancelled";
    sentAtDateTime: Dayjs;
    sendersUserId: number;
    sendersUserIdEmailLookup:string;
    htmlBody: string;

    static createFromApi(dto: OutboundEmailSupportTicketMessageDTO): OutboundEmailSupportTicketMessage {
        const supportTicketMessage = new OutboundEmailSupportTicketMessage();
        supportTicketMessage.id = dto.id;
        supportTicketMessage.message = dto.message;
        supportTicketMessage.submittedDateTime = dayjs.utc(dto.submittedDateTime);
        supportTicketMessage.type = dto.type;
        supportTicketMessage.externalEmailId = dto.externalEmailId;
        supportTicketMessage.status = dto.status;
        supportTicketMessage.sentAtDateTime = dayjs.utc(dto.sentAtDateTime);
        supportTicketMessage.sendersUserId = dto.sendersUserId;
        supportTicketMessage.htmlBody = dto.htmlBody;
        supportTicketMessage.direction = dto.direction;
        supportTicketMessage.fromEmail = dto.fromEmail;
        supportTicketMessage.subject = dto.subject;
        supportTicketMessage.ccEmail = dto.ccEmail;
        supportTicketMessage.toEmail = dto.toEmail;
        supportTicketMessage.sendersUserIdEmailLookup	 = dto.sendersUserIdEmailLookup	;
        return supportTicketMessage;
    }
}

type OutboundEmailSupportTicketMessageDTO = EmailSupportTicketMessageDTO & {
    externalEmailId: string;
    status:"Scheduled"| "Sending" | "Sent" | "Failed" | "Cancelled";
    sentAtDateTime: string;
    sendersUserId: number;
    sendersUserIdEmailLookup: string;
    htmlBody: string;
}

export class SubmittedFormSupportTicketMessage extends SupportTicketMessage {
    submittedEmail: string;
    submittedUserId: number;
    submittedUserIdEmailLookup: string;
    userAgent: string;
    ipAddress: string;
    formType: string;
    feedbackUrl: string;
    platform: string;

    static createFromApi(dto: SubmittedFormSupportTicketMessageDTO): SubmittedFormSupportTicketMessage {
        const supportTicketMessage = new SubmittedFormSupportTicketMessage();
        supportTicketMessage.id = dto.id;
        supportTicketMessage.message = dto.message;
        supportTicketMessage.submittedDateTime = dayjs.utc(dto.submittedDateTime);
        supportTicketMessage.type = dto.type;
        supportTicketMessage.formType = dto.formType;
        supportTicketMessage.submittedEmail = dto.submittedEmail;
        supportTicketMessage.submittedUserId = dto.submittedUserId;
        supportTicketMessage.userAgent = dto.userAgent;
        supportTicketMessage.ipAddress = dto.ipAddress;
        supportTicketMessage.direction = dto.direction;
        supportTicketMessage.feedbackUrl = dto.feedbackUrl;
        supportTicketMessage.platform = dto.platform;
        supportTicketMessage.submittedUserIdEmailLookup = dto.submittedUserIdEmailLookup;
        return supportTicketMessage;
    }
}

type SubmittedFormSupportTicketMessageDTO = SupportTicketMessageDTO & {
    submittedEmail: string;
    submittedUserId: number;
    submittedUserIdEmailLookup: string;
    userAgent: string;
    ipAddress: string;
    formType: string;
    feedbackUrl: string;
    platform: string;
}

export type SupportTicketSendEmailRequest = {
    ticketId: number;
    toEmail: string;
    subject: string;
    textBody: string;
    htmlBody: string;
}

export type GenericSubmittedFormRequest = {
    message:string;
    submittedEmail:string;
    metaData:string;
    submittedUrl:string;
    formType: "Feedback" | "Contact";
    platform: string;
}